@font-face {
    font-family: 'Gotham';
    src: local('Gotham'),
        url('https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/fonts/Gotham/Gotham-Book.otf')
            format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat'),
        url('https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/fonts/Montserrat/Montserrat-VariableFont_wght.ttf')
            format('truetype');
}

@font-face {
    font-family: 'Inter';
    src: local('Inter'),
        url('https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/fonts/Inter/Inter-VariableFont_slnt_wght.ttf')
            format('truetype');
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

body,
div {
    box-sizing: border-box;
}

button {
    cursor: pointer;
}
